<template>
    <v-container grid-list-md pa-0 relative>
        <v-layout row wrap>
            <v-flex xs12>

                <v-toolbar flat color="white">
                    <v-toolbar-title>Credit Note</v-toolbar-title>
                    <v-spacer></v-spacer>
                </v-toolbar>

                <!-- Add Ledger Head -->
                <v-card>
                    <v-card-text>
                        <v-form ref="form" @submit.prevent="store"
                                @keydown.native="form.errors.clear($event.target.name)"
                                @keyup.enter="store" lazy-validation>
                            <v-layout wrap>
                                <v-flex xs12 sm3>
                                    <v-text-field
                                            label="Serial Number" v-model="form.serial_no" name="serial_no"
                                            :error-messages="form.errors.get('serial_no')" :height="25"
                                            :readonly="form.loading"/>
                                </v-flex>
                                <v-flex xs12 sm7></v-flex>
                                <v-flex xs12 sm2>
                                    <v-btn color="primary" dark outline @click='submit' class="text-xs-right" :disabled="!form.serial_no">
                                        <v-icon small>add</v-icon>
                                        Search
                                    </v-btn>
                                </v-flex>
                            </v-layout>
                        </v-form>
                        <transition name="animate-css-transition"
                                    enter-active-class="animated fadeInRight"
                                    leave-active-class="animated fadeOutRight">
                            <v-btn v-if="this.download"
                                   fab
                                   small
                                   bottom
                                   right
                                   absolute
                                   @click.native.stop="printReport"
                                   style="z-index: 1; background: blue;bottom: 13px;"
                                   dark
                            >
                                <v-icon dark>cloud_download</v-icon>
                            </v-btn>
                        </transition>
                    </v-card-text>
                </v-card>
                <!--<v-card v-if="this.result">-->
                <!--<v-card-text>-->
                <!--<h3>Result Not Found.</h3>-->
                <!--</v-card-text>-->
                <!--</v-card>-->
                <!--<v-spacer></v-spacer>-->
                <!--<hr/>-->

                <v-data-table :headers="headers" :items="form.items.data" class="elevation-1"
                              hide-actions>

                    <template slot="items" slot-scope="props">
                        <td class="text-xs-left">{{props.item.entry_date}}</td>
                        <td class="text-xs-left">{{props.item.serial_no}}</td>
                        <td class="text-xs-left">
                            <tr v-for="(particular,i) in props.item.ledger_accounts" :key="i">
                                <td v-if="particular.dr > 0" style="position: absolute;margin-left: -25px;">
                                    {{particular.ledger_head}} (Dr.)
                                </td>

                                <td v-else-if="particular.cr > 0" style="position: absolute; margin-left: 35px;">To,
                                    {{particular.ledger_head}}
                                </td>

                                <br/>
                            </tr>
                            <!--<span ></span>-->
                            <!--<span v-else style="margin-left: 65px; position: absolute">{{particular.ledger_head}}</span>-->
                            <!--<br/>-->
                            <span><i> (Narration: {{props.item.narration}})</i></span>
                        </td>
                        <!--<td class="text-xs-center">{{props.item.lf_id}}</td>-->
                        <td>
                            <tr v-for="(particular,i) in props.item.ledger_accounts" :key="i">
                                <td v-if="parseInt(particular.dr) > 0"
                                    style="position: absolute; margin-top: -12px; margin-left: 45px">
                                    {{particular.dr}}
                                </td>
                                <br/>
                            </tr>
                        </td>
                        <td>
                            <tr v-for="(particular,i) in props.item.ledger_accounts" :key="i">
                                <td v-if="parseInt(particular.cr) > 0"
                                    style="position: absolute; margin-top: -12px; margin-left: 50px">
                                    {{particular.cr}}
                                </td>
                                <br/>
                            </tr>
                        </td>
                        <td class="text-xs-center">{{props.item.accountant.name}}</td>
                    </template>
                    <template slot="no-data">
                        Data not found.
                    </template>
                </v-data-table>

                <v-card v-if="this.tag">
                    <v-card-text>
                        <v-layout row wrap>
                            <v-flex xs12 sm10>

                                <v-btn class="text-xs-right" color="primary"
                                       @click="change" outline>Change
                                </v-btn>
                                <v-btn class="text-xs-right" color="secondary"
                                       @click="reverse" outline>Reverse
                                </v-btn>
                            </v-flex>
                        </v-layout>
                    </v-card-text>


                    <!--<v-btn slot="activator" color="primary" dark class="mb-2" outline><v-icon small>add</v-icon> &nbsp; Journal Entry</v-btn>-->
                    <v-card v-if="chg">
                        <v-card-text class="has-spinner">
                            <v-container fluid grid-list-sm pa-0>
                                <v-form ref="form">
                                    <v-layout row wrap v-for="(row, key) in form.ledger" :key="key">
                                        <v-layout xs12 v-if="row.cr > 0">
                                            <v-flex xs12 sm4>
                                                <v-select :readonly="form.loading" v-model="row.ledger_head_id"
                                                          :items="ledgerHeads"
                                                          label="Select Ledger Head" name="ledger_head_id" :height="25"
                                                          disabled>
                                                </v-select>
                                            </v-flex>
                                            <v-flex xs12 sm4>
                                                <v-select v-model="row.drcr" :items="drcrList" label="DR/CR" name="drcr"
                                                          :height="25"
                                                          :readonly="form.loading" disabled></v-select>
                                            </v-flex>
                                            <v-flex xs12 sm4>
                                                <v-text-field :readonly="form.loading" v-model="row.amount"
                                                              type="number"
                                                              name="amount"
                                                              label="Amount" :height="25"
                                                              @keyup="sumNetAmount(row)"></v-text-field>
                                            </v-flex>
                                            <v-flex xs12 sm1 text-xs-right>
                                                <v-btn :disabled="form.loading" @click="removeElement(row)"
                                                       class="error" outline icon
                                                       small>
                                                    <v-icon small class="error--text">remove</v-icon>
                                                </v-btn>
                                            </v-flex>
                                        </v-layout>
                                    </v-layout>

                                    <v-flex xs12>
                                        <v-textarea :readonly="form.loading" v-model="journal.narration"
                                                    label="Narration"
                                                    name="narration" :error-messages="form.errors.get('narration')"/>
                                    </v-flex>
                                    <div v-if="form.ledger && form.ledger.length"
                                         style="width: 100%; text-align: right; border-top: 1px solid #cacaca; padding-top: 10px;">
                                        <v-chip label outline color="primary">Total Amount: <strong>&nbsp;{{
                                            journal.final_amount }}</strong></v-chip>
                                    </div>
                                </v-form>
                            </v-container>
                        </v-card-text>

                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="info" :disabled="form.errors.any() || form.loading" @click="store" outline>
                                Save
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                    <v-card v-if="rev">
                        <v-card-text class="has-spinner">
                            <!--<spinner light="light" :loading="form.loading"/>-->
                            <v-container fluid grid-list-sm pa-0>
                                <v-form ref="form">
                                    <v-layout row wrap v-for="(row, key) in form.ledger" :key="key">
                                        <v-flex xs12 sm4>
                                            <v-select :readonly="form.loading" v-model="row.ledger_head_id"
                                                      :items="ledgerHeads"
                                                      label="Select Ledger Head" name="ledger_head_id" :height="25"
                                                      disabled>
                                            </v-select>
                                        </v-flex>
                                        <v-flex xs12 sm4>
                                            <v-select v-model="row.drcr" :items="drcrList" label="DR/CR" name="drcr"
                                                      :height="25"
                                                      :readonly="form.loading" disabled></v-select>
                                        </v-flex>
                                        <v-flex xs12 sm4>
                                            <v-text-field :readonly="form.loading" v-model="row.amount" type="number"
                                                          name="amount"
                                                          label="Amount" :height="25" disabled></v-text-field>
                                        </v-flex>
                                    </v-layout>

                                    <v-flex xs12>
                                        <v-textarea :readonly="form.loading" v-model="journal.narration"
                                                    label="Narration"
                                                    name="narration" :error-messages="form.errors.get('narration')"/>
                                    </v-flex>
                                </v-form>
                            </v-container>
                        </v-card-text>

                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="info" :disabled="form.errors.any() || form.loading" @click="store" outline>
                                Save
                            </v-btn>
                        </v-card-actions>
                    </v-card>


                </v-card>
                <!--<notification :notification="form.notification"/>-->
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
    import data from './modules/data'
    import computed from './modules/computed'
    import mounted from './modules/mounted'
    import created from './modules/created'
    import methods from './modules/methods'
    import watch from './modules/watch'

    // import Spinner from '@/components/Partials/Spinner'
    // import Notification from '@/components/Partials/Notification'

    export default {
        name: 'JournalList',
        // components: {Spinner, Notification},
        data: () => (data),
        mounted: mounted,
        computed: computed,
        methods: methods,
        created: created,
        watch: watch
    }
</script>
<style lang="scss" scoped>

</style>
